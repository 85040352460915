@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"), url(./fonts/Quicksand-Medium.ttf) format("truetype");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"), url(./fonts/Quicksand-Bold.ttf) format("truetype");
  font-weight: bold;
  font-display: swap;
}

:root {
  --color-palette-1: #173f5f;
  --color-palette-2: #20639b;
  --color-palette-3: #45bdb1;
  --color-palette-4: #f6d55c;
  --color-palette-5: #e9816f;
  --dark: #333333;

  --font-color: rgb(51, 51, 51);
  --background: #eeeeee;
  --navbar: var(--color-palette-1);
  --header: var(--color-palette-1);
  --link: var(--color-palette-2);
  --image-filter: opacity(100%);
  --tile-background: var(--color-palette-1);
  --tile-image-background: white;
  --badge: var(--color-palette-1);
}

[data-theme="dark"] {
  --font-color: #ffffff;
  --background: rgb(75, 75, 75);
  --navbar: var(--dark);
  --header: var(--dark);
  --link: var(--color-palette-3);
  --image-filter: opacity(90%);
  --tile-background: var(--dark);
  --tile-image-background: #e8e8e8;
  --badge: var(--color-palette-2);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Quicksand", sans-serif;
  font-size: 1.1rem;
  line-height: 1.4;
  text-align: left;

  color: var(--font-color);
  background-color: var(--background);
  transition: background-color 0.2s, color 0.2s;

  padding-top: 55px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
  margin: 0.75rem 0 0.75rem 0;
}
h1 {
  font-size: 1.7rem;
}
h2 {
  font-size: 1.55rem;
}
h3 {
  font-size: 1.4rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.1rem;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid var(--font-color);
}

img:not([src*=".svg"]) {
  filter: var(--image-filter);
  transition: filter 0.2s;

  max-width: 100%;
  height: auto;
}

ol, ul { padding-left: 1.1rem; }

ol > li, ul > li {
  margin-top: 15px;
  margin-bottom: 15px;
} 

nav.ck-nav-bar {
  width: 100%;
  background-color: var(--navbar);
  /*box-shadow: 0 5px 20px 0 rgba(130, 136, 171, 0.4);*/
  border-bottom: 2px solid #fff;
  height: 35px;

  padding: 10px;
  z-index: 1000;

  transition: background-color 0.2s;
}

nav.fixed {
  position: fixed;
  top: 0;
}

nav.ck-nav-bar > .brand {
  font-weight: bold;
  vertical-align: middle;
  margin-left: 10px;
}

nav.ck-nav-bar > .brand > a {
  color: #fff;
  font-size: 1.5rem;
}

nav.ck-nav-bar > .toggler {
  display: block;
  position: absolute;
  top: 1px;
  right: 20px;
  z-index: 1001;
}

nav.ck-nav-bar > .items {
  width: 100%;
  position: relative;
  height: 35px;
  padding: 10px;
  background-color: var(--navbar);
  border-bottom: 2px solid #fff;

  z-index: 1002;
  left: -10px;
  top: 9px;

  transition: background-color 0.2s;
}

nav.ck-nav-bar > .items > .item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5rem;
}

nav.ck-nav-bar > .items > .item > a {
  color: #fff;
}
nav.ck-nav-bar > .items > .item > a:hover {
  color: var(--color-palette-3);
}

div.hamburger {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px 0;
  transition: background-color 0.2s;
}

a,
a:hover {
  color: var(--link);
  text-decoration: none;
}

a.social-media-link {
  margin: 0 10px;
}

a.footer-link {
  color: white;
}
a.footer-link:hover {
  color: var(--color-palette-3);
}

header {
  background-color: var(--header);
  color: white;
  padding: 35px 4%;
  margin-bottom: 25px;

  transition: background-color 0.2s;
}
header > a {
  color: white;
}
header > a:hover {
  color: white;
}

.content {
  padding: 0 4%;
}

.social-media-share {
  margin: 1rem 0;
}
.social-media-share > button {
  margin-right: 0.5rem;
}

@media (min-width: 768px) {
  nav.ck-nav-bar > .items > .item {
    margin: 0 0.75rem;
  }

  header {
    padding: 35px 12%;
  }
  .content {
    padding: 0 12%;
  }
}

@media (min-width: 992px) {
  nav.ck-nav-bar > .items {
    display: block;
    width: 60%;
    text-align: right;

    position: absolute;
    top: 0;
    left: auto;
    right: 0;

    border-bottom: 0px;
  }
  nav.ck-nav-bar > .items > .item {
    margin: 0 1rem;
  }
  nav.ck-nav-bar > .toggler {
    display: none;
  }

  header {
    padding: 35px 20%;
  }
  .content {
    padding: 0 20%;
  }
}

@media (min-width: 1200px) {
  nav.ck-nav-bar > .items > .item {
    margin: 0 1.25rem;
  }
  header {
    padding: 35px 25%;
  }
  .content {
    padding: 0 25%;
  }
}

@media (min-width: 1600px) {
  nav.ck-nav-bar > .items > .item {
    margin: 0 1.5rem;
  }
  header {
    padding: 35px 30%;
  }
  .content {
    padding: 0 30%;
  }
}

/* Search Form */

.search {
  width: 300px;
  box-sizing: border-box;
  border: 2px solid rgb(197, 197, 197);
  border-radius: 20px;
  font-size: 18px;
  background-color: white;
  /*background-image: url("/images/search-icon.png");*/
  background-position: 7px 2px;
  background-repeat: no-repeat;
  padding: 4px 10px 4px 45px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin-left: 25px;
}

/* Loading Animation */

.page-loader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 75vh;
  margin: 25px 0;
}

.page-loader > h3 {
  position: relative;
  color: rgba(0, 0, 0, .3);
}
.page-loader > h3:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  max-width: 7em;
  white-space: nowrap;
  color: var(--color-palette-2);
  animation: loading 8s linear;
}
@keyframes loading {
  0% {
      max-width: 0;
  }
}

.tile {
  display: flex;
  flex-direction: column;
  min-height: 475px;
  word-wrap: break-word;
  background-clip: border-box;

  background-color: var(--tile-background);
  box-shadow: 0 5px 10px 0px rgba(130, 136, 171, 0.25);
  border-radius: 15px;
  overflow: hidden;

  transition: background-color 0.2s, border 0.2s;
}

.tile-image {
  background-color: var(--tile-image-background);
  min-height: 150px;
}

.tile-body {
  min-height: 150px;
  padding: 1.5rem;
  color: #fff;
}

.tile-title {
  font-weight: bold;
  font-size: 1.4rem;
  margin: 0.5rem 0;
}

.tile-title > a {
  color: var(--color-palette-3);
}

.btn {
  display: inline-block;
  font-family: inherit;
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--color-palette-1);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-1,
.btn-1:hover {
  color: #fff;
  background-color: var(--color-palette-3);
  border-color: var(--color-palette-3);
}

.btn-2,
.btn-2:hover {
  color: #fff;
  background-color: var(--color-palette-2);
  border-color: var(--color-palette-2);
}

.btn-3,
.btn-3:hover {
  color: #fff;
  background-color: var(--color-palette-5);
  border-color: var(--color-palette-5);
}

.alert {
  max-width: 85%;
  min-height: 100px;
  word-wrap: break-word;
  margin: 15px 0;
  padding: 0.4rem 1.4rem;
  color: white;
  border-radius: 15px;

  transition: background-color 0.2s, border 0.2s;
}

.alert.info {
  background-color:#1b7ac8;
}

.alert.warning {
  background-color:#d37f00;
}

.alert.danger {
  background-color:#e03e32;
}

.badge {
  display: inline-block;
  padding: 0.4em 0.8em;
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  background-color: var(--badge);
}

footer {
  min-height: 15vh;
  background-color: var(--dark);
  color: white;
  padding: 3%;
  margin-top: 50px;
  position: relative;
  bottom: 0;
}

footer hr {
  border-top: 1px solid rgb(186, 186, 186, 0.5);
  border-radius: 5px;
}

.pwa-update {
  display: none;
  position: fixed;
  text-align: center;
  width: calc(100% - 40px);
  background-color: var(--color-palette-1);
  color: white;
  bottom: 0;
  padding: 20px;
}

/* Style the light/dark mode toggle switch */

.theme-switch-wrapper {
  display: flex;
  align-items: center;
}
.theme-switch {
  display: inline-block;
  height: 26px;
  position: relative;
  width: 40px;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: var(--color-palette-4);
  border: 2px solid #ff8c00;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-position: right top;
  background-size: cover;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  content: "";
  height: 20px;
  position: absolute;
  transition: 0.4s;
  width: 20px;
}

input:checked + .slider {
  background-color: var(--color-palette-2);
  border: 2px solid #a3dafb;
  background-position: left top;
  background-size: cover;
  transition: 0.4s;
}

input:checked + .slider:before {
  transform: translateX(20px);
  position: absolute;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  top: 2px;
  left: -5px;
  background: linear-gradient(40deg, #8983f7, #a3dafb 70%);
  transition: linear 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.slider.round {
  border-radius: 28px;
}

.slider.round:before {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 2px;
  left: 3px;
  border-radius: 100%;
  background: linear-gradient(40deg, #ff0080, #ff8c00 70%);
  transition: linear 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* For content on the homepage */

.blog-top-list {
  min-height: 550px;
}

.section-block {
  padding: 40px 0;
  position: relative;
  color: white;
}

.section-block.first {
  background-color: var(--color-palette-1);
}
.section-block.second {
  background-color: var(--color-palette-2);
}
.section-block.third {
  background-color: var(--color-palette-3);
}

.section-block .inner {
  position: relative;
  font-size: 1.75rem;
  text-align: center;
  width: 100%;
}

.section-content {
  margin: 15px 0% 35px 0%;
}
.section-content.center {
  text-align: center;
}
