.ctn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ctn.left {
  justify-content: left;
}

.ctn .box {
  flex: 0 1 93%;
  margin: 10px;
}

.ctn.max-2 .box {
  flex: 0 1 92%;
}

.ctn .span-box {
  flex: 0 1 93%;
  margin: 10px;
}

@media (min-width: 640px) {
  .ctn .box {
    flex: 0 1 93%;
  }
}

@media (min-width: 768px) {
  .ctn .box {
    flex: 0 1 93%;
  }

  .ctn.max-2 .box {
    flex: 0 1 92%;
  }
}

@media (min-width: 1200px) {
  .ctn .box {
    flex: 0 1 31%;
  }

  .ctn.max-2 .box {
    flex: 0 1 46%;
  }
}
